import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Paper, Box, Stack } from "@mui/material";
import { Copyright } from "./copyright";
import { Outlet } from "react-router-dom";

function App() {
	return (
		<Container maxWidth="lg" sx={{ minHeight: "100vh", display: "flex" }}>
			<Grid container flex={1} justifyContent={"center"} alignItems={"center"}>
				<Grid md={7} sm={12}>
					<Paper sx={{ textAlign: "center", p: 5 }}>
						<Stack direction={"column"} spacing={3}>
							<Box>
								<img width={200} alt="membergift" src="https://moneynow3.com.br/wp-content/uploads/2023/03/logo.png" />
							</Box>
							<Outlet />
							<Box>
								<Copyright />
							</Box>
						</Stack>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
}

export default App;

import { BrowserRouter, Navigate, Route, Routes as RoutesDom } from "react-router-dom";

import App from "./app";
import Checkin from "./checkin";
import Video from "./video";

const Routes = () => {
	return (
		<BrowserRouter>
			<RoutesDom>
				<Route path="*" element={<Navigate to="/" />} />
				<Route path="/" element={<App />}>
					<Route index element={<Checkin />} />
					<Route path="/video/save" element={<Video />} />
				</Route>
			</RoutesDom>
		</BrowserRouter>
	);
};

export default Routes;

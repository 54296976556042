const list = [
	{
		dial: "93",
		id: "AFG",
		label: "Afeganistão",
	},
	{
		dial: "355",
		id: "ALB",
		label: "Albânia",
	},
	{
		dial: "213",
		id: "DZA",
		label: "Algéria",
	},
	{
		dial: "1684",
		id: "ASM",
		label: "Samoa Americana",
	},
	{
		dial: "376",
		id: "AND",
		label: "Andorra",
	},
	{
		dial: "244",
		id: "AGO",
		label: "Angola",
	},
	{
		dial: "1264",
		id: "AIA",
		label: "Anguilla",
	},
	{
		dial: "672",
		id: "ATA",
		label: "Antártida",
	},
	{
		dial: "1268",
		id: "ATG",
		label: "Antigua e Barbuda",
	},
	{
		dial: "54",
		id: "ARG",
		label: "Argentina",
	},
	{
		dial: "374",
		id: "ARM",
		label: "Armênia",
	},
	{
		dial: "297",
		id: "ABW",
		label: "Aruba",
	},
	{
		dial: "61",
		id: "AUS",
		label: "Austrália",
	},
	{
		dial: "43",
		id: "AUT",
		label: "Áustria",
	},
	{
		dial: "994",
		id: "AZE",
		label: "Azerbaijão",
	},
	{
		dial: "1242",
		id: "BHS",
		label: "Bahamas",
	},
	{
		dial: "973",
		id: "BHR",
		label: "Bahrein",
	},
	{
		dial: "880",
		id: "BGD",
		label: "Bangladesh",
	},
	{
		dial: "246",
		id: "BRB",
		label: "Barbados",
	},
	{
		dial: "375",
		id: "BLR",
		label: "Bielorrússia",
	},
	{
		dial: "32",
		id: "BEL",
		label: "Bélgica",
	},
	{
		dial: "501",
		id: "BLZ",
		label: "Belize",
	},
	{
		dial: "229",
		id: "BEN",
		label: "Benin",
	},
	{
		dial: "1441",
		id: "BMU",
		label: "Bermuda",
	},
	{
		dial: "975",
		id: "BTN",
		label: "Butão",
	},
	{
		dial: "591",
		id: "BOL",
		label: "Bolívia",
	},
	{
		dial: "387",
		id: "BIH",
		label: "Bósnia e Herzegovina",
	},
	{
		dial: "267",
		id: "BWA",
		label: "Botswana",
	},
	{
		dial: "47",
		id: "BVT",
		label: "Ilha Bouvet",
	},
	{
		dial: "55",
		id: "BRA",
		label: "Brasil",
	},
	{
		dial: "246",
		id: "IOT",
		label: "Território Britânico do Oceano Índico",
	},
	{
		dial: "673",
		id: "BRN",
		label: "Brunei",
	},
	{
		dial: "359",
		id: "BGR",
		label: "Bulgária",
	},
	{
		dial: "226",
		id: "BFA",
		label: "Burkina Faso",
	},
	{
		dial: "257",
		id: "BDI",
		label: "Burundi",
	},
	{
		dial: "855",
		id: "KHM",
		label: "Camboja",
	},
	{
		dial: "237",
		id: "CMR",
		label: "Camarões",
	},
	{
		dial: "1",
		id: "CAN",
		label: "Canadá",
	},
	{
		dial: "238",
		id: "CPV",
		label: "Cabo Verde",
	},
	{
		dial: "1345",
		id: "CYM",
		label: "Ilhas Cayman",
	},
	{
		dial: "236",
		id: "CAF",
		label: "República Centro-Africana",
	},
	{
		dial: "235",
		id: "TCD",
		label: "Chade",
	},
	{
		dial: "56",
		id: "CHL",
		label: "Chile",
	},
	{
		dial: "86",
		id: "CHN",
		label: "China",
	},
	{
		dial: "61",
		id: "CXR",
		label: "Ilha Christmas",
	},
	{
		dial: "672",
		id: "CCK",
		label: "Ilhas Cocos (Keeling)",
	},
	{
		dial: "57",
		id: "COL",
		label: "Colômbia",
	},
	{
		dial: "269",
		id: "COM",
		label: "Comores",
	},
	{
		dial: "242",
		id: "COG",
		label: "Congo",
	},
	{
		dial: "242",
		id: "COD",
		label: "Congo (DR)",
	},
	{
		dial: "682",
		id: "COK",
		label: "Ilhas Cook",
	},
	{
		dial: "506",
		id: "CRI",
		label: "Costa Rica",
	},
	{
		dial: "225",
		id: "CIV",
		label: "Costa do Marfim",
	},
	{
		dial: "385",
		id: "HRV",
		label: "Croácia",
	},
	{
		dial: "53",
		id: "CUB",
		label: "Cuba",
	},
	{
		dial: "357",
		id: "CYP",
		label: "Chipre",
	},
	{
		dial: "420",
		id: "CZE",
		label: "República Tcheca",
	},
	{
		dial: "45",
		id: "DNK",
		label: "Dinamarca",
	},
	{
		dial: "253",
		id: "DJI",
		label: "Djibuti",
	},
	{
		dial: "1767",
		id: "DMA",
		label: "Dominica",
	},
	{
		dial: "1809",
		id: "DOM",
		label: "República Dominicana",
	},
	{
		dial: "593",
		id: "ECU",
		label: "Equador",
	},
	{
		dial: "20",
		id: "EGY",
		label: "Egito",
	},
	{
		dial: "503",
		id: "SLV",
		label: "El Salvador",
	},
	{
		dial: "240",
		id: "GNQ",
		label: "Guiné Equatorial",
	},
	{
		dial: "291",
		id: "ERI",
		label: "Eritreia",
	},
	{
		dial: "372",
		id: "EST",
		label: "Estônia",
	},
	{
		dial: "251",
		id: "ETH",
		label: "Etiópia",
	},
	{
		dial: "500",
		id: "FLK",
		label: "Ilhas Malvinas",
	},
	{
		dial: "298",
		id: "FRO",
		label: "Ilhas Faroe",
	},
	{
		dial: "679",
		id: "FJI",
		label: "Fiji",
	},
	{
		dial: "358",
		id: "FIN",
		label: "Finlândia",
	},
	{
		dial: "33",
		id: "FRA",
		label: "França",
	},
	{
		dial: "594",
		id: "GUF",
		label: "Guiana Francesa",
	},
	{
		dial: "689",
		id: "PYF",
		label: "Polinésia Francesa",
	},
	{
		dial: "33",
		id: "ATF",
		label: "Terras Austrais e Antárticas Francesas",
	},
	{
		dial: "241",
		id: "GAB",
		label: "Gabão",
	},
	{
		dial: "220",
		id: "GMB",
		label: "Gâmbia",
	},
	{
		dial: "995",
		id: "GEO",
		label: "Geórgia",
	},
	{
		dial: "49",
		id: "DEU",
		label: "Alemanha",
	},
	{
		dial: "233",
		id: "GHA",
		label: "Gana",
	},
	{
		dial: "350",
		id: "GIB",
		label: "Gibraltar",
	},
	{
		dial: "30",
		id: "GRC",
		label: "Grécia",
	},
	{
		dial: "299",
		id: "GRL",
		label: "Groelândia",
	},
	{
		dial: "1473",
		id: "GRD",
		label: "Granada",
	},
	{
		dial: "590",
		id: "GLP",
		label: "Guadalupe",
	},
	{
		dial: "1671",
		id: "GUM",
		label: "Guão",
	},
	{
		dial: "502",
		id: "GTM",
		label: "Guatemala",
	},
	{
		dial: "224",
		id: "GIN",
		label: "Guiné",
	},
	{
		dial: "245",
		id: "GNB",
		label: "Guiné-Bissau",
	},
	{
		dial: "592",
		id: "GUY",
		label: "Guiana",
	},
	{
		dial: "509",
		id: "HTI",
		label: "Haiti",
	},
	{
		dial: "672",
		id: "HMD",
		label: "Ilhas Heard e McDonald",
	},
	{
		dial: "39",
		id: "VAT",
		label: "Vaticano",
	},
	{
		dial: "504",
		id: "HND",
		label: "Honduras",
	},
	{
		dial: "852",
		id: "HKG",
		label: "Hong Kong",
	},
	{
		dial: "36",
		id: "HUN",
		label: "Hungria",
	},
	{
		dial: "354",
		id: "ISL",
		label: "Islândia",
	},
	{
		dial: "91",
		id: "IND",
		label: "Índia",
	},
	{
		dial: "62",
		id: "IDN",
		label: "Indonésia",
	},
	{
		dial: "98",
		id: "IRN",
		label: "Iran",
	},
	{
		dial: "964",
		id: "IRQ",
		label: "Iraque",
	},
	{
		dial: "353",
		id: "IRL",
		label: "Irlanda",
	},
	{
		dial: "972",
		id: "ISR",
		label: "Israel",
	},
	{
		dial: "39",
		id: "ITA",
		label: "Italia",
	},
	{
		dial: "1876",
		id: "JAM",
		label: "Jamaica",
	},
	{
		dial: "81",
		id: "JPN",
		label: "Japão",
	},
	{
		dial: "962",
		id: "JOR",
		label: "Jornânia",
	},
	{
		dial: "7",
		id: "KAZ",
		label: "Cazaquistão",
	},
	{
		dial: "254",
		id: "KEN",
		label: "Quênia",
	},
	{
		dial: "686",
		id: "KIR",
		label: "Kiribati",
	},
	{
		dial: "850",
		id: "PRK",
		label: "Coreia do Norte",
	},
	{
		dial: "82",
		id: "KOR",
		label: "Coreia do Sul",
	},
	{
		dial: "965",
		id: "KWT",
		label: "Kuwait",
	},
	{
		dial: "996",
		id: "KGZ",
		label: "Quirguistão",
	},
	{
		dial: "856",
		id: "LAO",
		label: "Laos",
	},
	{
		dial: "371",
		id: "LVA",
		label: "Letônia",
	},
	{
		dial: "961",
		id: "LBN",
		label: "Líbano",
	},
	{
		dial: "266",
		id: "LSO",
		label: "Lesoto",
	},
	{
		dial: "231",
		id: "LBR",
		label: "Libéria",
	},
	{
		dial: "218",
		id: "LBY",
		label: "Líbia",
	},
	{
		dial: "423",
		id: "LIE",
		label: "Liechtenstein",
	},
	{
		dial: "370",
		id: "LTU",
		label: "Lituânia",
	},
	{
		dial: "352",
		id: "LUX",
		label: "Luxemburgo",
	},
	{
		dial: "853",
		id: "MAC",
		label: "Macao",
	},
	{
		dial: "389",
		id: "MKD",
		label: "Macedônia",
	},
	{
		dial: "261",
		id: "MDG",
		label: "Madagascar",
	},
	{
		dial: "265",
		id: "MWI",
		label: "Malawi",
	},
	{
		dial: "60",
		id: "MYS",
		label: "Malásia",
	},
	{
		dial: "960",
		id: "MDV",
		label: "Maldivas",
	},
	{
		dial: "223",
		id: "MLI",
		label: "Mali",
	},
	{
		dial: "356",
		id: "MLT",
		label: "Malta",
	},
	{
		dial: "692",
		id: "MHL",
		label: "Ilhas Marshall",
	},
	{
		dial: "596",
		id: "MTQ",
		label: "Martinica",
	},
	{
		dial: "222",
		id: "MRT",
		label: "Mauritânia",
	},
	{
		dial: "230",
		id: "MUS",
		label: "Maurício",
	},
	{
		dial: "269",
		id: "MYT",
		label: "Mayotte",
	},
	{
		dial: "52",
		id: "MEX",
		label: "México",
	},
	{
		dial: "691",
		id: "FSM",
		label: "Micronésia",
	},
	{
		dial: "373",
		id: "MDA",
		label: "Moldova",
	},
	{
		dial: "377",
		id: "MCO",
		label: "Mônaco",
	},
	{
		dial: "976",
		id: "MNG",
		label: "Mongólia",
	},
	{
		dial: "1664",
		id: "MSR",
		label: "Montserrat",
	},
	{
		dial: "212",
		id: "MAR",
		label: "Marrocos",
	},
	{
		dial: "258",
		id: "MOZ",
		label: "Moçambique",
	},
	{
		dial: "95",
		id: "MMR",
		label: "Birmânia",
	},
	{
		dial: "264",
		id: "NAM",
		label: "Namíbia",
	},
	{
		dial: "674",
		id: "NRU",
		label: "Nauru",
	},
	{
		dial: "977",
		id: "NPL",
		label: "Nepal",
	},
	{
		dial: "31",
		id: "NLD",
		label: "Holanda",
	},
	{
		dial: "599",
		id: "ANT",
		label: "Antilhas Holandesas",
	},
	{
		dial: "687",
		id: "NCL",
		label: "Nova Caledônia",
	},
	{
		dial: "64",
		id: "NZL",
		label: "Nova Zelândia",
	},
	{
		dial: "505",
		id: "NIC",
		label: "Nicarágua",
	},
	{
		dial: "227",
		id: "NER",
		label: "Niger",
	},
	{
		dial: "234",
		id: "NGA",
		label: "Nigéria",
	},
	{
		dial: "683",
		id: "NIU",
		label: "Niue",
	},
	{
		dial: "672",
		id: "NFK",
		label: "Ilha Norfolk",
	},
	{
		dial: "1670",
		id: "MNP",
		label: "Ilhas Marianas do Norte",
	},
	{
		dial: "47",
		id: "NOR",
		label: "Noruega",
	},
	{
		dial: "968",
		id: "OMN",
		label: "Omã",
	},
	{
		dial: "92",
		id: "PAK",
		label: "Paquistão",
	},
	{
		dial: "680",
		id: "PLW",
		label: "Palau",
	},
	{
		dial: "970",
		id: "PSE",
		label: "Palestina",
	},
	{
		dial: "507",
		id: "PAN",
		label: "Panamá",
	},
	{
		dial: "675",
		id: "PNG",
		label: "Papua-Nova Guiné",
	},
	{
		dial: "595",
		id: "PRY",
		label: "Paraguai",
	},
	{
		dial: "51",
		id: "PER",
		label: "Peru",
	},
	{
		dial: "63",
		id: "PHL",
		label: "Filipinas",
	},
	{
		dial: "672",
		id: "PCN",
		label: "Ilhas Picárnia",
	},
	{
		dial: "48",
		id: "POL",
		label: "Polônia",
	},
	{
		dial: "351",
		id: "PRT",
		label: "Portugal",
	},
	{
		dial: "1787",
		id: "PRI",
		label: "Porto Rico",
	},
	{
		dial: "974",
		id: "QAT",
		label: "Catar",
	},
	{
		dial: "262",
		id: "REU",
		label: "Reunião",
	},
	{
		dial: "40",
		id: "ROM",
		label: "Romênia",
	},
	{
		dial: "70",
		id: "RUS",
		label: "Rússia",
	},
	{
		dial: "250",
		id: "RWA",
		label: "Ruanda",
	},
	{
		dial: "290",
		id: "SHN",
		label: "Santa Helena",
	},
	{
		dial: "1869",
		id: "KNA",
		label: "São Cristóvão",
	},
	{
		dial: "1758",
		id: "LCA",
		label: "Santa Lúcia",
	},
	{
		dial: "508",
		id: "SPM",
		label: "São Pedro e Miquelon",
	},
	{
		dial: "1784",
		id: "VCT",
		label: "São Vicente e Granadinas",
	},
	{
		dial: "684",
		id: "WSM",
		label: "Samoa",
	},
	{
		dial: "378",
		id: "SMR",
		label: "São Marino",
	},
	{
		dial: "239",
		id: "STP",
		label: "Sao Tomé e Príncipe",
	},
	{
		dial: "966",
		id: "SAU",
		label: "Arábia Saudita",
	},
	{
		dial: "221",
		id: "SEN",
		label: "Senegal",
	},
	{
		dial: "381",
		id: "SRB",
		label: "Sérvia e Montenegro",
	},
	{
		dial: "248",
		id: "SYC",
		label: "Seicheles",
	},
	{
		dial: "232",
		id: "SLE",
		label: "República da Serra Leoa",
	},
	{
		dial: "65",
		id: "SGP",
		label: "Singapura",
	},
	{
		dial: "421",
		id: "SVK",
		label: "Eslováquia",
	},
	{
		dial: "386",
		id: "SVN",
		label: "Eslovênia",
	},
	{
		dial: "677",
		id: "SLB",
		label: "Ilhas Salomão",
	},
	{
		dial: "252",
		id: "SOM",
		label: "Somália",
	},
	{
		dial: "27",
		id: "ZAF",
		label: "África do Sul",
	},
	{
		dial: "500",
		id: "SGS",
		label: "Ilhas Geórgia do Sul e Sandwich do Sul",
	},
	{
		dial: "34",
		id: "ESP",
		label: "Espanha",
	},
	{
		dial: "94",
		id: "LKA",
		label: "Sri Lanka",
	},
	{
		dial: "249",
		id: "SDN",
		label: "Sudão",
	},
	{
		dial: "597",
		id: "SUR",
		label: "Suriname",
	},
	{
		dial: "47",
		id: "SJM",
		label: "Esvalbarde",
	},
	{
		dial: "268",
		id: "SWZ",
		label: "Suazilândia",
	},
	{
		dial: "46",
		id: "SWE",
		label: "Suécia",
	},
	{
		dial: "41",
		id: "CHE",
		label: "Suiça",
	},
	{
		dial: "963",
		id: "SYR",
		label: "Síria",
	},
	{
		dial: "886",
		id: "TWN",
		label: "Taiwan",
	},
	{
		dial: "992",
		id: "TJK",
		label: "Tajiquistão",
	},
	{
		dial: "255",
		id: "TZA",
		label: "Tanzânia",
	},
	{
		dial: "66",
		id: "THA",
		label: "Tailândia",
	},
	{
		dial: "670",
		id: "TLS",
		label: "Timor-Leste",
	},
	{
		dial: "228",
		id: "TGO",
		label: "Togo",
	},
	{
		dial: "690",
		id: "TKL",
		label: "Toquelau",
	},
	{
		dial: "676",
		id: "TON",
		label: "Tonga",
	},
	{
		dial: "1868",
		id: "TTO",
		label: "Trinidad e Tobago",
	},
	{
		dial: "216",
		id: "TUN",
		label: "Tunísia",
	},
	{
		dial: "90",
		id: "TUR",
		label: "Turquia",
	},
	{
		dial: "7370",
		id: "TKM",
		label: "Turcomenistão",
	},
	{
		dial: "1649",
		id: "TCA",
		label: "Ilhas Turks e Caicos",
	},
	{
		dial: "688",
		id: "TUV",
		label: "Tuvalu",
	},
	{
		dial: "256",
		id: "UGA",
		label: "Uganda",
	},
	{
		dial: "380",
		id: "UKR",
		label: "Ucrânia",
	},
	{
		dial: "971",
		id: "ARE",
		label: "Emirados Árabes",
	},
	{
		dial: "44",
		id: "GBR",
		label: "Reino Unido",
	},
	{
		dial: "1",
		id: "USA",
		label: "Estados Unidos",
	},
	{
		dial: "1",
		id: "UMI",
		label: "Ilhas Menores Distantes dos Estados Unidos",
	},
	{
		dial: "598",
		id: "URY",
		label: "Uruguai",
	},
	{
		dial: "998",
		id: "UZB",
		label: "Uzbequistão",
	},
	{
		dial: "678",
		id: "VUT",
		label: "Vanuatu",
	},
	{
		dial: "58",
		id: "VEN",
		label: "Venezuela",
	},
	{
		dial: "84",
		id: "VNM",
		label: "Vietnam",
	},
	{
		dial: "1284",
		id: "VGB",
		label: "Ilhas Virgens Inglesas",
	},
	{
		dial: "1340",
		id: "VIR",
		label: "Ilhas Virgens",
	},
	{
		dial: "681",
		id: "WLF",
		label: "Wallis e Futuna",
	},
	{
		dial: "212",
		id: "ESH",
		label: "Saara Ocidental",
	},
	{
		dial: "967",
		id: "YEM",
		label: "Iêmen",
	},
	{
		dial: "260",
		id: "ZMB",
		label: "Zâmbia",
	},
	{
		dial: "263",
		id: "ZWE",
		label: "Zimbábue",
	},
];

export default list;

import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FieldError, useForm } from "react-hook-form";
import { FormContainer, SelectElement, TextFieldElement } from "react-hook-form-mui";
import axios from "axios";
import env from "./env";
import countryJson from "./country.ts";

type CheckinType = {
	name: string;
	email: string;
	country: string;
	phone: string;
};

function Checkin() {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>("");
	const formContext = useForm<CheckinType>({
		defaultValues: {
			name: "",
			email: "",
			country: "",
			phone: "",
		},
	});
	const countryWatch = formContext.watch("country")
		? countryJson.find((x) => x.id === formContext.watch("country"))
		: null;

	function onLogin(payload: CheckinType) {
		setLoading(true);
		setTimeout(async () => {
			try {
				const req: CheckinType = {
					country: payload.country,
					email: payload.email.toLowerCase().trim(),
					name: payload.name.toUpperCase().trim(),
					phone: `+${countryWatch?.dial}${payload.phone}`,
				};
				const { data } = await axios.post(`${env.baseUrl}/auth`, req);
				if (data.link) {
					window.open(data.link, "_self");
				}
			} catch (ex: any) {
				let message = "Ocorreu um erro. Tente novamente.";
				if (ex.response.data.message) {
					message = ex.response.data.message;
				}
				setError(message);
			} finally {
				setLoading(false);
			}
		}, 3000);
	}

	const validateEmail = (error: FieldError): string => {
		if (error.type === "required") {
			return error.message || "Este campo é obrigatório.";
		}
		if (error.type === "pattern") {
			return "Informe um e-mail válido";
		}
		return "";
	};

	return (
		<>
			<Box>
				<Typography variant="h5" color={"primary.main"}>
					ASSISTA O MONEYNOW 3.0 AQUI
				</Typography>
			</Box>
			<Box>
				<FormContainer formContext={formContext}>
					<Grid container rowSpacing={3} spacing={2}>
						<Grid xs={12}>
							<TextFieldElement
								required
								validation={{
									required: "Este campo é obrigatório.",
									pattern: {
										value: new RegExp("^([(A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|')]{3,})+\\s+([(A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ|')\\s]{3,})+$", "g"),
										message: "Informe o nome completo.",
									},
								}}
								fullWidth
								name={"name"}
								label={"Nome Completo"}
							/>
						</Grid>
						<Grid xs={12}>
							<TextFieldElement
								required
								parseError={validateEmail}
								validation={{ required: "Este campo é obrigatório." }}
								fullWidth
								name={"email"}
								label={"E-mail"}
								type="email"
							/>
						</Grid>
						<Grid sm={6} xs={12}>
							<SelectElement
								required
								validation={{ required: "Este campo é obrigatório." }}
								options={countryJson}
								fullWidth
								name={"country"}
								label={"País"}
								sx={{ textAlign: "left" }}
							/>
						</Grid>
						<Grid sm={6} xs={12}>
							<TextFieldElement
								required
								validation={{ required: "Este campo é obrigatório." }}
								InputProps={{
									startAdornment: countryWatch ? `+${countryWatch.dial}` : null,
								}}
								fullWidth
								name={"phone"}
								label={"Telefone"}
							/>
						</Grid>
					</Grid>
				</FormContainer>
			</Box>
			{error.length > 0 && (
				<Box>
					<Alert variant="filled" color="error">
						{error}
					</Alert>
				</Box>
			)}
			<Box>
				<LoadingButton
					loading={loading}
					onClick={formContext.handleSubmit(onLogin)}
					sx={{ p: 1.5 }}
					size="large"
					fullWidth
					variant="outlined">
					Acessar
				</LoadingButton>
			</Box>
		</>
	);
}

export default Checkin;

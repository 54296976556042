import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import axios from "axios";
import env from "./env";

type CheckinType = {
	link: string;
	code: string;
};

function Video() {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>("");
	const formContext = useForm<CheckinType>({
		defaultValues: {
			link: "",
			code: "",
		},
	});

	function onSave(payload: CheckinType) {
		setLoading(true);
		setTimeout(async () => {
			try {
				await axios.post(`${env.baseUrl}/video`, { link: payload.link }, { params: { code: payload.code } });
				formContext.reset();
				setError("");
			} catch (ex: any) {
				let message = "Ocorreu um erro. Tente novamente.";
				if (ex.response.data.message) {
					message = ex.response.data.message;
				}
				setError(message);
			} finally {
				setLoading(false);
			}
		}, 3000);
	}

	return (
		<>
			<Box>
				<Typography variant="h5" color={"primary.main"}>
					CADASTRAR VÍDEO REDIRECIONAMENTO
				</Typography>
			</Box>
			<Box>
				<FormContainer formContext={formContext}>
					<Grid container rowSpacing={3} spacing={2}>
						<Grid xs={12}>
							<TextFieldElement
								required
								validation={{
									required: "Este campo é obrigatório.",
								}}
								fullWidth
								name={"link"}
								label={"Link do vídeo"}
							/>
						</Grid>
						<Grid xs={12}>
							<TextFieldElement
								required
								validation={{ required: "Este campo é obrigatório." }}
								fullWidth
								name={"code"}
								label={"Código de Cadastramento"}
							/>
						</Grid>
					</Grid>
				</FormContainer>
			</Box>
			{error.length > 0 && (
				<Box>
					<Alert variant="filled" color="error">
						{error}
					</Alert>
				</Box>
			)}
			<Box>
				<LoadingButton
					loading={loading}
					onClick={formContext.handleSubmit(onSave)}
					sx={{ p: 1.5 }}
					size="large"
					fullWidth
					variant="outlined">
					Cadastrar
				</LoadingButton>
			</Box>
		</>
	);
}

export default Video;
